import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Box, Button, 
  Flex, FormControl, Grid, GridItem, Heading, Input, Link, List, ListItem, Select, Stack, 
  Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, UnorderedList, VStack } from '@chakra-ui/react'
import React, { useState }  from 'react'
import Header from '../../components/Header'
import FooterItem from '../../components/FooterItem'
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { SearchIcon } from '@chakra-ui/icons';
import { useSelector, useDispatch } from "react-redux";
import { ApiEndpoints, ECHANNELLING, Methods } from '../../constants';
import * as actions from "../store/actions";
import LatestItems1 from '../../components/LatestItems1';

function Radiology() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState("Any");
    const [doctorName, setDoctorName] = useState("*");
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
      };
    const handleClearDate = () => {
        setSelectedDate(null);
      };

        //--------------------------------------------------------------------------------------------------------------
  const getDoctorList = () => {
    // console.log(selectedDate);
    // console.log(spId);
    // console.log(doctorName);

    sessionStorage.setItem('selectedDate', selectedDate);
    sessionStorage.setItem('spId', '33');
    sessionStorage.setItem('doctorName', doctorName);

    const URL =
      ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.doctorAvailability;
    fetch(URL, {
      method: Methods.POST,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: JSON.stringify({
        sp_id: '21',
        date: selectedDate,
        name: doctorName,
      }),
    })
      .then((response) => response.json())
      .then((object) => {
        if (object) {
          const payload = {
            data: object.doctort_list,
          };
          // console.log(object.doctort_list);
          dispatch(actions.getDoctorList(payload));
          // Link
          navigate("/doctorsearch");
        } else {
          console.log(object);
        }
      })
      .catch((error) => console.log(error));
  };

  const videos = [
    {
      id: 5,
      title: 'හිසරදය දරුණු රෝග ලක්ශණයක් විය හැකිය.',
      url: 'https://www.youtube.com/watch?v=evK3zyYx1Ws',
    },
    {
      id: 5,
      title: 'හිසරදය දරුණු රෝග ලක්ශණයක් විය හැකිය.',
      url: 'https://www.youtube.com/watch?v=evK3zyYx1Ws',
    },
    {
      id: 5,
      title: 'හිසරදය දරුණු රෝග ලක්ශණයක් විය හැකිය.',
      url: 'https://www.youtube.com/watch?v=evK3zyYx1Ws',
    },
    {
      id: 5,
      title: 'හිසරදය දරුණු රෝග ලක්ශණයක් විය හැකිය.',
      url: 'https://www.youtube.com/watch?v=evK3zyYx1Ws',
    },

    // Add more video objects as needed
  ];

  return (
    <div>
        <Header/>
      {/* <Heading mt="20px" ml="20px" size={'lg'} mb={5}>Rheumatology and Sports Medicine</Heading> */}

      <Box
        backgroundImage={require('../../img/pagecovers/radiology-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Radiology & Imaging
        </Text>
      </Box>

      <Grid flexDirection={['column','row']} width="100%" templateColumns={["1fr", "2fr", "1fr 2fr 1fr"]} p={2} mb={10}>
        <Flex flexDirection={'column'} alignItems="center">
        <Flex>
        <Heading mt="20px" ml="20px" size={'md'} mb="10px">Book Appointments</Heading>
      </Flex>
      <Flex flexDirection="column" background="#1A908F" p={5} width="100%" borderRadius="8px" mx="auto">
        <Heading size="xs" color="white" mb="5px">Radiology and Imaging</Heading>
        <FormControl>
        {/* <FormLabel>First name</FormLabel> */}
        <Input backgroundColor={'white'} width="100%" mt="5px" placeholder='First name' mb={2} 
        onChange={(event) => setDoctorName(event.target.value)} />
        <Box display="flex" alignItems="center">
          <Input
            type="date"
            value={selectedDate || ''}
            onChange={handleDateChange}
            px={3}
            py={2}
            borderRadius="md"
            borderColor="gray.300"
            background="white"
            top="5px"
            mb={25}
            width="100%"
            _hover={{ borderColor: 'gray.400' }}
            _focus={{ outline: 'none', borderColor: 'blue.500', boxShadow: '0 0 0 2px rgba(66, 153, 225, 0.6)' }}
          />
          {/* {selectedDate && (
            <Button ml={2} size="sm" variant='outline' color="white" onClick={handleClearDate}
            top="50px"
            left="30px">
              Clear
            </Button>
          )} */}
        </Box>
        </FormControl>

        <Stack direction='row' spacing={4}>
            {/* <Link as={RouterLink} to='/doctorsearch'> */}
            <Button leftIcon={<SearchIcon />} 
            bg="#0E5453" 
            color="white" 
            variant='solid'
            // top="170px"
            // left="-190px"
            _hover={{
              bg: '#004242',
            }}
            _focus={{
              bg: '#004242',
            }}
            onClick={() => getDoctorList()}
            >
                Find Your Doctor
            </Button>
            {/* </Link> */}
            </Stack>
      </Flex>
      {/* <Flex>
        <Heading mt="20px" ml="20px" size={'md'} mb="5px">Resident Rheumatologist</Heading>
      </Flex>
      <Flex borderWidth="1px" borderRadius="lg" p={4} 
      display="flex" width="100%" alignItems="center" bg={'#266564'} mx="auto" flexDirection="column">
        <Avatar size="2xl" src={require('../../img/doctorImages/dr-harindu.jpg')} borderWidth="3px" borderColor={'whiteAlpha.700'} />
        <VStack spacing={0} alignItems="center">
            <Text fontSize="xl" fontWeight="bold" color="white">Dr. Harindu Wijesinghe</Text>
            <Text fontSize="sm" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
                MBBS IS.L.I, MD [Coll, MCP (UK)]{'\n'}
                Consultant in Rheumatology & Rehabilitation{'\n'}
                Specialist in Sports Medicine
            </Text>
        </VStack>
      </Flex> */}

      <Flex>
        <Heading mt="20px" alignItems="center" textAlign="center" ml="20px" size={'md'} mb="5px">Appointment Reservation</Heading>
      </Flex>
      <Box justifyContent="center" borderWidth="1px" width="100%" borderRadius="lg" p={4} 
      display="flex" mt="5px" alignItems="center" bg={'#266564'} mx="auto" flexDirection="column">
        <VStack spacing={0} alignItems="center">
            <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
            0115 777 777 {'\n'}
            0115 777 888
            </Text>
            <Text fontSize="md" textAlign="center" color="whiteAlpha.800">&bull; Surgery &  Admission Details</Text>
            <Text fontSize="lg" textAlign="center" color="whiteAlpha.800" whiteSpace={'pre-line'}>
            0765 577 111{'\n'}
            0770 079 079
            </Text>
        </VStack>
      </Box>
      </Flex>
      <Grid pt="10px" templateColumns={['1fr', '1fr', '1fr', '1fr 1fr']} gap={4}>
        <Flex flexDirection="column" p={10} width="100%" gridColumn="1 / -1">
        <Box>
        <Text mb={10} whiteSpace={"pre-line"}>
        At  we take pride in offering advanced and comprehensive radiology services to our patients. 
        Our Radiology Department is staffed with highly skilled radiologists, technologists, and 
        support staff who are dedicated to providing exceptional care and accurate diagnoses.{'\n'}{'\n'}

        State-of-the-art Imaging Technology: We are equipped with the latest imaging technology, 
        including digital X-ray machines, computed tomography (CT) scanners, magnetic resonance 
        imaging (MRI) scanners, ultrasound machines, Interventional radiology procedures,3D 
        mammography and DEXA scans. Our cutting-edge technology ensures precise and detailed images, 
        enabling our radiologists to make accurate diagnoses.{'\n'}{'\n'}


        PACS Technology: Our radiology unit is equipped with the revolutionary digital imaging 
        technology known as the Picture Archiving and Communication System (PACS). This system has 
        transformed radiology practices and brings several advantages to our facility. Here are 
        some key benefits of implementing the PACS system in radiology:

        Comprehensive Services: We offer a wide range of radiology services to meet the diverse 
        needs of our patients,{'\n'}{'\n'}


        <Box mt={2}>
        {/* <Heading size="sm">AREAS OF CONSULTATION</Heading> */}
        <UnorderedList>
            <ListItem>Digital X-rays provide quick and accurate imaging for diagnosing fractures, infections, 
        and other conditions.</ListItem>

            <ListItem>CT scan images produce detailed cross-sectional images of the body, aiding in the 
        detection and evaluation of various diseases and conditions.</ListItem>

            <ListItem>MRI scan powerful magnetic fields and radio waves to generate detailed images of the 
        body's internal structures, helping diagnose conditions such as tumors, neurological 
        disorders, and joint injuries.</ListItem>

            <ListItem>Ultrasound scan use sound waves to create real-time images of organs, tissues, and blood 
        flow, assisting in the diagnosis of conditions affecting the abdomen, pelvis, and vascular 
        system.</ListItem>

            <ListItem>Interventional radiology procedures: such as angiography, biopsies, and minimally invasive 
        treatments, provide targeted and precise therapies, reducing the need for traditional 
        surgery.</ListItem>

        <ListItem>3D mammography: known as digital breast tomosynthesis, utilize advanced imaging technology 
        to capture multiple images of the breast from different angles, providing a 
        three-dimensional view. This improves the accuracy of detecting breast abnormalities 
        and enables better visualization of breast tissue, particularly in women with dense 
        breasts. </ListItem>

        <ListItem>DEXA scans: also known as dual-energy X-ray absorptiometry scans, are non-invasive 
        imaging procedures used to measure bone density and assess the risk of osteoporosis. 
        They provide accurate and detailed information about bone health, aiding in diagnosis 
        and monitoring of bone-related conditions. </ListItem>

        <ListItem>CT Angiography: Non-invasive imaging technique that uses computed tomography (CT) to 
        visualize blood vessels in various parts of the body. It provides detailed images that 
        help diagnose and evaluate conditions such as arterial blockages or aneurysms. </ListItem>
      
        </UnorderedList>
        </Box> 

        </Text>
        </Box>    

        {/* <Box mt={'10'}>
  <Heading size={'md'} mb={'5'}>FAQs</Heading>
        <Accordion allowToggle>
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        What factors can affect fertility and reproductive health?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      Several factors can influence fertility and reproductive health, including age, underlying 
      medical conditions, lifestyle choices, hormonal imbalances, genetic factors, and 
      environmental factors. It's essential to understand these factors and seek appropriate 
      care to optimize fertility potential.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        When should couples seek fertility evaluation and treatment?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      Couples should consider seeking fertility evaluation and treatment if they 
      have been actively trying to conceive for one year without success (or six months 
      if the woman is over 35 years old). However, individuals with known fertility issues 
      or medical conditions should consult a fertility specialist before attempting to conceive.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        What are the common fertility tests and procedures available?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      Fertility tests and procedures may include semen analysis, hormonal evaluations, ultrasound 
      imaging, hysterosalpingography (HSG) to assess the fallopian tubes, ovarian reserve testing, 
      and genetic screening. Additionally, assisted reproductive technologies (ART) like in vitro 
      fertilization (IVF) and intrauterine insemination (IUI) may be recommended based on 
      individual circumstances.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight={'semibold'}>
        Are there any lifestyle changes that can enhance fertility?
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Text>
      Yes, certain lifestyle changes can positively impact fertility. Maintaining healthy body 
      weight, adopting a balanced diet, reducing stress levels, avoiding tobacco and excessive 
      alcohol consumption, exercising regularly, and managing chronic conditions can contribute 
      to improved reproductive health.
      </Text>
    </AccordionPanel>
  </AccordionItem>
</Accordion>
</Box>     */}
            
      </Flex>
      <Box p={2} mt={'-10'}>
  <Heading size={'md'} mb={5}>Related Media</Heading>
  <Grid templateColumns="repeat(auto-fit, minmax(300px, 1fr))" gap={6} pr={6} pl={6}>
        {videos.map((video) => (
          <GridItem key={video.id}>
            <Box
              as="iframe"
              width="100%"
              height="250px"
              src={`https://www.youtube.com/embed/${video.url.split('=')[1]}`}
              title={video.title}
              allowFullScreen
              borderRadius="md"
              boxShadow="md"
            />
            <Heading as="h3" size="xs" mt={4}>
              {video.title}
            </Heading>
          </GridItem>
        ))}
      </Grid>
  </Box>
      </Grid>
      <Flex flexDirection={'column'} alignItems="left" p={4}>
        <LatestItems1/>
      </Flex>
      </Grid>
      <FooterItem/>
    </div>
  )
}

export default Radiology
